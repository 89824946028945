// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import CfAllergyEmergencyAlertsUponInPatientDetail from "../../blocks/CfAllergyEmergencyAlertsUponInPatientDetail/src/CfAllergyEmergencyAlertsUponInPatientDetail";
import CfIntegrationWithCustomChatbot from "../../blocks/CfIntegrationWithCustomChatbot/src/CfIntegrationWithCustomChatbot";
import QuestionBank from "../../blocks/QuestionBank/src/QuestionBank";
import CfNurseEncounter from "../../blocks/CfNurseEncounter/src/CfNurseEncounter";
import OrganisationHierarchy from "../../blocks/OrganisationHierarchy/src/OrganisationHierarchy";
import Documentation from "../../blocks/Documentation/src/Documentation";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import CfPharmacyBilling from "../../blocks/CfPharmacyBilling/src/CfPharmacyBilling";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import Download from "../../blocks/Download/src/Download";
import SubscriptionBilling2 from "../../blocks/SubscriptionBilling2/src/SubscriptionBilling2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import CfIpBilling from "../../blocks/CfIpBilling/src/CfIpBilling";
import LocationbasedAlerts from "../../blocks/LocationbasedAlerts/src/LocationbasedAlerts";
import CfPharmacyPreapprovals from "../../blocks/CfPharmacyPreapprovals/src/CfPharmacyPreapprovals";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import CfVoiceToText3rdPartyIntegration from "../../blocks/CfVoiceToText3rdPartyIntegration/src/CfVoiceToText3rdPartyIntegration";
import PaymentAdmin from "../../blocks/PaymentAdmin/src/PaymentAdmin";
import FullTeamVisibility2 from "../../blocks/FullTeamVisibility2/src/FullTeamVisibility2";
import SmsSettings from "../../blocks/SmsSettings/src/SmsSettings";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import PosIntegrationBillingInvoicingSystem2 from "../../blocks/PosIntegrationBillingInvoicingSystem2/src/PosIntegrationBillingInvoicingSystem2";
import Notes from "../../blocks/Notes/src/Notes";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Customform from "../../blocks/customform/src/Customform";
import RecurringAppointments from "../../blocks/RecurringAppointments/src/RecurringAppointments";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import StripeIntegration from "../../blocks/stripepayments/src/StripeIntegration";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Notifications from "../../blocks/notifications/src/Notifications";
import CfIntegrationWithInsuranceCompany from "../../blocks/CfIntegrationWithInsuranceCompany/src/CfIntegrationWithInsuranceCompany";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Analytics from "../../blocks/analytics/src/Analytics";
import RefundManagement from "../../blocks/RefundManagement/src/RefundManagement";
import CfCdss3rdPartyIntegration from "../../blocks/CfCdss3rdPartyIntegration/src/CfCdss3rdPartyIntegration";
import Referrals from "../../blocks/Referrals/src/Referrals";
import AuditTrail from "../../blocks/AuditTrail/src/AuditTrail";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import AutomaticRenewals from "../../blocks/automaticrenewals/src/AutomaticRenewals";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import CfLabRadiologyMaster from "../../blocks/CfLabRadiologyMaster/src/CfLabRadiologyMaster";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import UserStatus from "../../blocks/userstatus/src/UserStatus";
import DetailedDownloadHistory2 from "../../blocks/DetailedDownloadHistory2/src/DetailedDownloadHistory2";
import CfOpBilling from "../../blocks/CfOpBilling/src/CfOpBilling";
import TermsAndConditions3 from "../../blocks/TermsAndConditions3/src/TermsAndConditions3";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import LiveFeedScheduling from "../../blocks/LiveFeedScheduling/src/LiveFeedScheduling";
import CfWhatsappChatInt from "../../blocks/CfWhatsappChatInt/src/CfWhatsappChatInt";
import CfIntegrationWithHl7Lab from "../../blocks/CfIntegrationWithHl7Lab/src/CfIntegrationWithHl7Lab";
import WaitingList from "../../blocks/WaitingList/src/WaitingList";
import ReceiptCustomisation from "../../blocks/ReceiptCustomisation/src/ReceiptCustomisation";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import CfIntegrationForPacsSystemForRadiology from "../../blocks/CfIntegrationForPacsSystemForRadiology/src/CfIntegrationForPacsSystemForRadiology";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import AutomatedPrioritisation from "../../blocks/AutomatedPrioritisation/src/AutomatedPrioritisation";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import CfPharmacyClaims from "../../blocks/CfPharmacyClaims/src/CfPharmacyClaims";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AutomaticReminders from "../../blocks/AutomaticReminders/src/AutomaticReminders";
import Sorting from "../../blocks/sorting/src/Sorting";
import TeamBuilder from "../../blocks/TeamBuilder/src/TeamBuilder";
import Reservations from "../../blocks/Reservations/src/Reservations";
import Location from "../../blocks/location/src/Location";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import LanguageOptions from "../../blocks/LanguageOptions/src/LanguageOptions";
import Print from "../../blocks/print/src/Print";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import FileAttachment from "../../blocks/FileAttachment/src/FileAttachment";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import InventoryManagement3 from "../../blocks/InventoryManagement3/src/InventoryManagement3";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import CustomisedOrderStatus from "../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus";
import CfDoctorEncounter from "../../blocks/CfDoctorEncounter/src/CfDoctorEncounter";
import SalesReporting from "../../blocks/SalesReporting/src/SalesReporting";



const routeMap = {
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
CfAllergyEmergencyAlertsUponInPatientDetail:{
 component:CfAllergyEmergencyAlertsUponInPatientDetail,
path:"/CfAllergyEmergencyAlertsUponInPatientDetail"},
CfIntegrationWithCustomChatbot:{
 component:CfIntegrationWithCustomChatbot,
path:"/CfIntegrationWithCustomChatbot"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
CfNurseEncounter:{
 component:CfNurseEncounter,
path:"/CfNurseEncounter"},
OrganisationHierarchy:{
 component:OrganisationHierarchy,
path:"/OrganisationHierarchy"},
Documentation:{
 component:Documentation,
path:"/Documentation"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
CfPharmacyBilling:{
 component:CfPharmacyBilling,
path:"/CfPharmacyBilling"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
Download:{
 component:Download,
path:"/Download"},
SubscriptionBilling2:{
 component:SubscriptionBilling2,
path:"/SubscriptionBilling2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
CfIpBilling:{
 component:CfIpBilling,
path:"/CfIpBilling"},
LocationbasedAlerts:{
 component:LocationbasedAlerts,
path:"/LocationbasedAlerts"},
CfPharmacyPreapprovals:{
 component:CfPharmacyPreapprovals,
path:"/CfPharmacyPreapprovals"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
CfVoiceToText3rdPartyIntegration:{
 component:CfVoiceToText3rdPartyIntegration,
path:"/CfVoiceToText3rdPartyIntegration"},
PaymentAdmin:{
 component:PaymentAdmin,
path:"/PaymentAdmin"},
FullTeamVisibility2:{
 component:FullTeamVisibility2,
path:"/FullTeamVisibility2"},
SmsSettings:{
 component:SmsSettings,
path:"/SmsSettings"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
PosIntegrationBillingInvoicingSystem2:{
 component:PosIntegrationBillingInvoicingSystem2,
path:"/PosIntegrationBillingInvoicingSystem2"},
Notes:{
 component:Notes,
path:"/Notes"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Customform:{
 component:Customform,
path:"/Customform"},
RecurringAppointments:{
 component:RecurringAppointments,
path:"/RecurringAppointments"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
StripeIntegration:{
 component:StripeIntegration,
path:"/StripeIntegration"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
CfIntegrationWithInsuranceCompany:{
 component:CfIntegrationWithInsuranceCompany,
path:"/CfIntegrationWithInsuranceCompany"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
RefundManagement:{
 component:RefundManagement,
path:"/RefundManagement"},
CfCdss3rdPartyIntegration:{
 component:CfCdss3rdPartyIntegration,
path:"/CfCdss3rdPartyIntegration"},
Referrals:{
 component:Referrals,
path:"/Referrals"},
AuditTrail:{
 component:AuditTrail,
path:"/AuditTrail"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
AutomaticRenewals:{
 component:AutomaticRenewals,
path:"/AutomaticRenewals"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
CfLabRadiologyMaster:{
 component:CfLabRadiologyMaster,
path:"/CfLabRadiologyMaster"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
UserStatus:{
 component:UserStatus,
path:"/UserStatus"},
DetailedDownloadHistory2:{
 component:DetailedDownloadHistory2,
path:"/DetailedDownloadHistory2"},
CfOpBilling:{
 component:CfOpBilling,
path:"/CfOpBilling"},
TermsAndConditions3:{
 component:TermsAndConditions3,
path:"/TermsAndConditions3"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
LiveFeedScheduling:{
 component:LiveFeedScheduling,
path:"/LiveFeedScheduling"},
CfWhatsappChatInt:{
 component:CfWhatsappChatInt,
path:"/CfWhatsappChatInt"},
CfIntegrationWithHl7Lab:{
 component:CfIntegrationWithHl7Lab,
path:"/CfIntegrationWithHl7Lab"},
WaitingList:{
 component:WaitingList,
path:"/WaitingList"},
ReceiptCustomisation:{
 component:ReceiptCustomisation,
path:"/ReceiptCustomisation"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
CfIntegrationForPacsSystemForRadiology:{
 component:CfIntegrationForPacsSystemForRadiology,
path:"/CfIntegrationForPacsSystemForRadiology"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
AutomatedPrioritisation:{
 component:AutomatedPrioritisation,
path:"/AutomatedPrioritisation"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
CfPharmacyClaims:{
 component:CfPharmacyClaims,
path:"/CfPharmacyClaims"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
AutomaticReminders:{
 component:AutomaticReminders,
path:"/AutomaticReminders"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
TeamBuilder:{
 component:TeamBuilder,
path:"/TeamBuilder"},
Reservations:{
 component:Reservations,
path:"/Reservations"},
Location:{
 component:Location,
path:"/Location"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
Print:{
 component:Print,
path:"/Print"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
EmailNotifications2:{
 component:EmailNotifications2,
path:"/EmailNotifications2"},
FileAttachment:{
 component:FileAttachment,
path:"/FileAttachment"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
InventoryManagement3:{
 component:InventoryManagement3,
path:"/InventoryManagement3"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
CustomisedOrderStatus:{
 component:CustomisedOrderStatus,
path:"/CustomisedOrderStatus"},
CfDoctorEncounter:{
 component:CfDoctorEncounter,
path:"/CfDoctorEncounter"},
SalesReporting:{
 component:SalesReporting,
path:"/SalesReporting"},

  Home: {
component:Favourites,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
