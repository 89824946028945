import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import MobilePhoneLogInAdapter from "../../blocks/adapters/src/MobilePhoneLogInAdapter";
import MobilePhoneToAdditionalDetailsAdapter from "../../blocks/adapters/src/MobilePhoneToAdditionalDetailsAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import SocialMediaLogInAdapter from "../../blocks/adapters/src/SocialMediaLogInAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";

//Assembler generated adapters start
const mobilePhoneLogInAdapter = new MobilePhoneLogInAdapter();
const mobilePhoneToAdditionalDetailsAdapter = new MobilePhoneToAdditionalDetailsAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance:HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>
                Welcome to MedEastEx!
              </Text>
            </View>

            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={'InfoPage'}
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content={'Alert'}
              onPress={() => this.showAlert("Example", "This happened")}
            />
<CustomTextItem content={'Categoriessubcategories'}  onPress={() => navigation.navigate("Categoriessubcategories")} />
<CustomTextItem content={'core'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'PhoneNumberInput'}  onPress={() => navigation.navigate("PhoneNumberInput")} />
<CustomTextItem content={'CountryCodeSelector'}  onPress={() => navigation.navigate("CountryCodeSelector")} />
<CustomTextItem content={'OTPInputAuth'}  onPress={() => navigation.navigate("OTPInputAuth")} />
<CustomTextItem content={'EmailAccountRegistration'}  onPress={() => navigation.navigate("EmailAccountRegistration")} />
<CustomTextItem content={'SocialMediaAccountRegistrationScreen'}  onPress={() => navigation.navigate("SocialMediaAccountRegistrationScreen")} />
<CustomTextItem content={'social-media-account'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Customform'}  onPress={() => navigation.navigate("Customform")} />
<CustomTextItem content={'Pushnotifications'}  onPress={() => navigation.navigate("Pushnotifications")} />
<CustomTextItem content={'Notifications'}  onPress={() => navigation.navigate("Notifications")} />
<CustomTextItem content={'StripeIntegration'}  onPress={() => navigation.navigate("StripeIntegration")} />
<CustomTextItem content={'utilities'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Analytics'}  onPress={() => navigation.navigate("Analytics")} />
<CustomTextItem content={'BulkUploading'}  onPress={() => navigation.navigate("BulkUploading")} />
<CustomTextItem content={'VisualAnalytics'}  onPress={() => navigation.navigate("VisualAnalytics")} />
<CustomTextItem content={'Scheduling'}  onPress={() => navigation.navigate("Scheduling")} />
<CustomTextItem content={'ForgotPassword'}  onPress={() => navigation.navigate("ForgotPassword")} />
<CustomTextItem content={'MobileAccountLoginBlock'}  onPress={() => navigation.navigate("MobileAccountLoginBlock")} />
<CustomTextItem content={'EmailAccountLoginBlock'}  onPress={() => navigation.navigate("EmailAccountLoginBlock")} />
<CustomTextItem content={'SocialMediaAccountLoginScreen'}  onPress={() => navigation.navigate("SocialMediaAccountLoginScreen")} />
<CustomTextItem content={'RequestManagement'}  onPress={() => navigation.navigate("RequestManagement")} />
<CustomTextItem content={'ReviewApprovalAdmin'}  onPress={() => navigation.navigate("ReviewApprovalAdmin")} />
<CustomTextItem content={'Ordermanagement'}  onPress={() => navigation.navigate("Ordermanagement")} />
<CustomTextItem content={'Catalogue'}  onPress={() => navigation.navigate("Catalogue")} />
<CustomTextItem content={'Dashboard'}  onPress={() => navigation.navigate("Dashboard")} />
<CustomTextItem content={'ImportExportData'}  onPress={() => navigation.navigate("ImportExportData")} />
<CustomTextItem content={'Notificationsettings'}  onPress={() => navigation.navigate("Notificationsettings")} />
<CustomTextItem content={'AutomaticRenewals'}  onPress={() => navigation.navigate("AutomaticRenewals")} />
<CustomTextItem content={'HelpCentre'}  onPress={() => navigation.navigate("HelpCentre")} />
<CustomTextItem content={'Tasks'}  onPress={() => navigation.navigate("Tasks")} />
<CustomTextItem content={'AccountGroups'}  onPress={() => navigation.navigate("AccountGroups")} />
<CustomTextItem content={'Appointments'}  onPress={() => navigation.navigate("Appointments")} />
<CustomTextItem content={'Location'}  onPress={() => navigation.navigate("Location")} />
<CustomTextItem content={'Print'}  onPress={() => navigation.navigate("Print")} />
<CustomTextItem content={'ApiIntegration'}  onPress={() => navigation.navigate("ApiIntegration")} />
<CustomTextItem content={'LandingPage'}  onPress={() => navigation.navigate("LandingPage")} />
<CustomTextItem content={'UserStatus'}  onPress={() => navigation.navigate("UserStatus")} />
<CustomTextItem content={'Sorting'}  onPress={() => navigation.navigate("Sorting")} />
<CustomTextItem content={'CarouselDisplay'}  onPress={() => navigation.navigate("CarouselDisplay")} />
<CustomTextItem content={'Favourites'}  onPress={() => navigation.navigate("Favourites")} />
<CustomTextItem content={'CustomisedOrderStatus'}  onPress={() => navigation.navigate("CustomisedOrderStatus")} />
<CustomTextItem content={'PaymentAdmin'}  onPress={() => navigation.navigate("PaymentAdmin")} />
<CustomTextItem content={'Settings5'}  onPress={() => navigation.navigate("Settings5")} />
<CustomTextItem content={'CfIntegrationWithCustomChatbot'}  onPress={() => navigation.navigate("CfIntegrationWithCustomChatbot")} />
<CustomTextItem content={'CfIntegrationWithHl7Lab'}  onPress={() => navigation.navigate("CfIntegrationWithHl7Lab")} />
<CustomTextItem content={'InvoiceBilling'}  onPress={() => navigation.navigate("InvoiceBilling")} />
<CustomTextItem content={'CfCdss3rdPartyIntegration'}  onPress={() => navigation.navigate("CfCdss3rdPartyIntegration")} />
<CustomTextItem content={'QuestionBank'}  onPress={() => navigation.navigate("QuestionBank")} />
<CustomTextItem content={'CfVoiceToText3rdPartyIntegration'}  onPress={() => navigation.navigate("CfVoiceToText3rdPartyIntegration")} />
<CustomTextItem content={'RefundManagement'}  onPress={() => navigation.navigate("RefundManagement")} />
<CustomTextItem content={'SmsSettings'}  onPress={() => navigation.navigate("SmsSettings")} />
<CustomTextItem content={'AdminConsole'}  onPress={() => navigation.navigate("AdminConsole")} />
<CustomTextItem content={'FullTeamVisibility2'}  onPress={() => navigation.navigate("FullTeamVisibility2")} />
<CustomTextItem content={'RolesPermissions'}  onPress={() => navigation.navigate("RolesPermissions")} />
<CustomTextItem content={'DetailedDownloadHistory2'}  onPress={() => navigation.navigate("DetailedDownloadHistory2")} />
<CustomTextItem content={'PeopleManagement2'}  onPress={() => navigation.navigate("PeopleManagement2")} />
<CustomTextItem content={'AutomaticReminders'}  onPress={() => navigation.navigate("AutomaticReminders")} />
<CustomTextItem content={'OrganisationHierarchy'}  onPress={() => navigation.navigate("OrganisationHierarchy")} />
<CustomTextItem content={'Download'}  onPress={() => navigation.navigate("Download")} />
<CustomTextItem content={'EmailNotifications2'}  onPress={() => navigation.navigate("EmailNotifications2")} />
<CustomTextItem content={'ReceiptCustomisation'}  onPress={() => navigation.navigate("ReceiptCustomisation")} />
<CustomTextItem content={'SalesReporting'}  onPress={() => navigation.navigate("SalesReporting")} />
<CustomTextItem content={'SubscriptionBilling2'}  onPress={() => navigation.navigate("SubscriptionBilling2")} />
<CustomTextItem content={'TeamBuilder'}  onPress={() => navigation.navigate("TeamBuilder")} />
<CustomTextItem content={'LocationbasedAlerts'}  onPress={() => navigation.navigate("LocationbasedAlerts")} />
<CustomTextItem content={'AutomatedPrioritisation'}  onPress={() => navigation.navigate("AutomatedPrioritisation")} />
<CustomTextItem content={'InventoryManagement3'}  onPress={() => navigation.navigate("InventoryManagement3")} />
<CustomTextItem content={'PosIntegrationBillingInvoicingSystem2'}  onPress={() => navigation.navigate("PosIntegrationBillingInvoicingSystem2")} />
<CustomTextItem content={'AuditTrail'}  onPress={() => navigation.navigate("AuditTrail")} />
<CustomTextItem content={'CustomisableUserProfiles'}  onPress={() => navigation.navigate("CustomisableUserProfiles")} />
<CustomTextItem content={'LanguageOptions'}  onPress={() => navigation.navigate("LanguageOptions")} />
<CustomTextItem content={'Reservations'}  onPress={() => navigation.navigate("Reservations")} />
<CustomTextItem content={'CfIntegrationForPacsSystemForRadiology'}  onPress={() => navigation.navigate("CfIntegrationForPacsSystemForRadiology")} />
<CustomTextItem content={'CfIntegrationWithInsuranceCompany'}  onPress={() => navigation.navigate("CfIntegrationWithInsuranceCompany")} />
<CustomTextItem content={'RecurringAppointments'}  onPress={() => navigation.navigate("RecurringAppointments")} />
<CustomTextItem content={'LiveFeedScheduling'}  onPress={() => navigation.navigate("LiveFeedScheduling")} />
<CustomTextItem content={'Notes'}  onPress={() => navigation.navigate("Notes")} />
<CustomTextItem content={'Documentation'}  onPress={() => navigation.navigate("Documentation")} />
<CustomTextItem content={'ElasticSearch'}  onPress={() => navigation.navigate("ElasticSearch")} />
<CustomTextItem content={'ContentManagement'}  onPress={() => navigation.navigate("ContentManagement")} />
<CustomTextItem content={'CfAllergyEmergencyAlertsUponInPatientDetail'}  onPress={() => navigation.navigate("CfAllergyEmergencyAlertsUponInPatientDetail")} />
<CustomTextItem content={'UploadMedia2'}  onPress={() => navigation.navigate("UploadMedia2")} />
<CustomTextItem content={'CfWhatsappChatInt'}  onPress={() => navigation.navigate("CfWhatsappChatInt")} />
<CustomTextItem content={'Referrals'}  onPress={() => navigation.navigate("Referrals")} />
<CustomTextItem content={'WaitingList'}  onPress={() => navigation.navigate("WaitingList")} />
<CustomTextItem content={'FileAttachment'}  onPress={() => navigation.navigate("FileAttachment")} />
<CustomTextItem content={'TermsAndConditions3'}  onPress={() => navigation.navigate("TermsAndConditions3")} />
<CustomTextItem content={'CfPharmacyBilling'}  onPress={() => navigation.navigate("CfPharmacyBilling")} />
<CustomTextItem content={'CfPharmacyPreapprovals'}  onPress={() => navigation.navigate("CfPharmacyPreapprovals")} />
<CustomTextItem content={'CfPharmacyClaims'}  onPress={() => navigation.navigate("CfPharmacyClaims")} />
<CustomTextItem content={'CfNurseEncounter'}  onPress={() => navigation.navigate("CfNurseEncounter")} />
<CustomTextItem content={'CfDoctorEncounter'}  onPress={() => navigation.navigate("CfDoctorEncounter")} />
<CustomTextItem content={'CfOpBilling'}  onPress={() => navigation.navigate("CfOpBilling")} />
<CustomTextItem content={'CfIpBilling'}  onPress={() => navigation.navigate("CfIpBilling")} />
<CustomTextItem content={'CfLabRadiologyMaster'}  onPress={() => navigation.navigate("CfLabRadiologyMaster")} />

          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default HomeScreen;